import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import parse from 'html-react-parser'

// Elements
import HeroDetail from 'components/elements/HeroDetail'
import Image from 'components/shared/Image'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import CustomLink from 'components/shared/CustomLink'
import TitleDefault from 'components/elements/TitleDefault'

const StyledImage = styled(Image)`
  width: 100%;
  height: 210px;
  object-fit: cover;
  margin-bottom: 0.5rem !important;
`

const CustomTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 1.5rem;
`

const Inner = styled.div`
      background: #fff;
    box-shadow: 0 0 4px rgb(0 0 0 / 25%);
`

const PageTemplate = ({
  data: {
    page: { path, title, acf, yoast_meta: yoast },
    services: { edges },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <HeroDetail fields={acf.banner} title={title} />

      <section>
        <div className="container py-lg-3">
          <div className="row py-5">
            <div className="col-12">
              <TitleDefault className="mb-5" withStripe>Onze diensten</TitleDefault>
            </div>
            {edges.map(({ node }, index) => (
              <div className="col-md-4 mb-5" key={index}>
                <Inner>
                  <CustomLink to={node.path}><StyledImage src={node.acf.detailpage.image} className="mb-4" /></CustomLink>
                  <div className="p-3">
                    <CustomTitle>{parse(node.title)}</CustomTitle>
                  </div>
                </Inner>
              </div>
            ))}
          </div>
        </div>
      </section>

      
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
          description
        }
      }
      
      yoast_meta {
        name
        content
        property
      }
    }

    services:  allWordpressWpDiensten(sort: {fields: date, order: ASC}) {
      edges {
        node {
          title
          path
          acf {
            inhoud {
              description
              icon {
                localFile {
                  publicURL
                }
              }
            }
            detailpage {
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                    openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PageTemplate
